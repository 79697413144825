<template>
	<div class="header">
		<a
			class="header-left"
			:href="tengivaUrl"
		>
			<Icon name="md-chevron_left" />
			<span class="text-[14px]">Back to tengiva.com</span>
		</a>

		<Logo
			src="/images/logo-black.svg"
			class="logo"
		/>

		<div class="header-right">
			<div class="content">
				<p>
					<Icon
						:optical-size="20"
						name="md-support_agent"
					/>
					<span
						>Call us at
						<a
							href="tel:+(1)438402400"
							class="underline"
						>
							+1 438-940-2400
						</a>
					</span>
				</p>
				<p class="text-neutral-600 text-[12px]">Monday to Friday 9 AM to 5 PM EST</p>
			</div>

			<div class="content">
				<p class="self-end">100% secured</p>
				<p>platform & payment</p>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
const { mode } = useRuntimeConfig().public;

const tengivaUrl = computed(() => {
	switch (mode) {
		case "development":
			return "http://localhost:5035";
		case "dev":
			return "https://t2dev.ca/";
		case "staging":
			return "https://tengiva.online/";
		case "production":
		default:
			return "https://tengiva.com/";
	}
});
</script>

<style lang="scss" scoped>
.header {
	@apply h-[72px] px-[40px] py-[26px] flex items-center relative justify-center;
	@screen tablet {
		@apply px-0;
	}
	.header-left {
		@apply absolute left-[40px] inline-flex items-center self-center leading-[20px] text-neutral-600;
		@screen tablet {
			@apply left-0;
		}
	}

	.header-right {
		@apply absolute right-[40px] space-x-[40px] flex text-[14px] leading-[20px];
		@screen tablet {
			@apply space-x-[8px] right-0;
		}
		@screen mobile {
			@apply hidden;
		}
		.content {
			@apply text-[14px] flex flex-col;
			p {
				@apply inline-flex leading-[20px];
			}
		}
	}
}
</style>
